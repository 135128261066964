.filter-table {
    padding-bottom: 50px;
    position: relative;

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    .loading-view {
        min-height: calc(100vh - 300px);
        top: 0;
        left: 0;
    }

    &--container {
        position: relative;
    }

    table {
        min-width: 100%;

        th {
            position: sticky;
            top: 135px;
            background-color: var(--basecoloralt);
            z-index: 3;
            min-width: 100px;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            input {
                box-sizing: border-box;
                width: 100%;
                font-size: 12px;
                -webkit-appearance: none;
                border: 1px solid var(--bordercoloralt);
                background-color: var(--basecolor);
                border-radius: 4px;
                padding: 3px 6px;
                color: var(--fontcolor);

                &:focus {
                    outline: none;
                    border-color: var(--primarylight);
                }
            }
        }

        tbody {

            tr {
                cursor: pointer;

                &:hover {
                    td {
                        background-color: var(--basecoloralt);
                    }
                }
            }

            td {
                min-width: 100px;
                white-space: normal;
                padding: 8px;
                font-size: .9rem;
                user-select: none;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }

    &-row {
        &--selected {
            td {
                background-color: var(--basecoloralt);
                color: var(--primarydark);

                // &:first-child {
                //     border-left: 3px solid var(--primarylight);
                // }
            }
        }
    }

    &-header {
        &--label {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 14px 8px;

            span {
                display: block;
                font-size: .6rem;
                text-align: left;
                color: var(--primaryDark);
                letter-spacing: .05rem;
                text-transform: uppercase;
                padding-right: 20px;
            }
        }

        &--sort {
            margin-left: 10px;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);

            svg {
                width: 10px;
                height: 10px;
                transform: rotate(-90deg);
            }

            &-asc {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }
}


.pagination {
    display: flex;
    justify-content: space-between;
    padding: 10px 8px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    background-color: var(--basecoloralt);
    max-width: var(--maxWidth);
    border-radius: 10px;

    &-prev,
    &-next {
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        line-height: 26px;
        display: flex;
        align-items: center;

        &:hover {
            color: var(--fontcoloralt);

            svg {
                fill: var(--primarylight);
            }
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &-prev {
        svg {
            transform: rotate(90deg);
        }
    }

    &-next {
        svg {
            transform: rotate(-90deg);
        }
    }

    &-pages {
        background-color: var(--basecoloralt);
        padding: 5px 10px;
        border-radius: 6px;
        font-size: 14px;
        pointer-events: none;
    }

    .disabled {
        opacity: 0;
        pointer-events: none;
    }
}

#modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {
        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#BDBEC2, .3);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 840px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: scroll;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;

            &::after,
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: var(--primary);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }

            &::before {
                transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }

            &:hover {
                opacity: .6;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px 0;
        }
    }

    .modal-view {
        .modal-content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }
    }

    .modal-deal {
        height: 100%;

        &--main {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            .input-wrapper--checkbox {
                display: flex;
            }

            .admin & {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        &--info {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;

            &>div {
                flex: 1;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        &.modal-inner {
            max-width: 900px;

            .modal-content {
                max-height: calc(100vh - 170px);
            }

            .modal-actions {
                justify-content: space-between;

                .btn {
                    background-color: var(--secondary);

                    &--property-finder {
                        background-color: var(--primarydark);

                        .rai-container.rai-dots {
                            color: #fff !important;
                        }
                    }
                }
            }

            .properties,
            .contacts {

                .property {
                    display: grid;
                    gap: 0 20px;
                    grid-template-columns: 1fr 1fr;
                    background-color: #f5f6f9;
                    padding: 10px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    position: relative;
                    padding-bottom: 30px;
                    cursor: auto;

                    .btn-remove {
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        background-color: var(--secondary);
                        border-radius: 6px;
                        padding: 7px;

                        span {
                            font-size: .8rem;
                        }

                        .btn--icon {
                            margin-right: 7px;
                        }
                    }
                }

                &>.btn {
                    margin-top: 10px;
                }
            }

            .contacts {
                .contact {
                    background-color: #f5f6f9;
                    padding: 10px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    position: relative;

                    &-extended {
                        display: grid;
                        gap: 0 20px;
                        grid-template-columns: 1fr 1fr;
                        cursor: auto;
                    }

                    &-main {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);

                        &>.input-wrapper {
                            flex: 50%;
                        }

                        .input-wrapper--checkbox {
                            align-self: flex-end;
                            margin-bottom: 20px;
                            margin-left: 20px;
                        }
                    }

                    .actions {
                        display: flex;
                        justify-content: space-between;
                    }

                    .btn-remove,
                    .btn-expand {
                        background-color: var(--secondary);
                        border-radius: 6px;
                        padding: 7px;

                        span {
                            font-size: .8rem;
                        }

                        .btn--icon {
                            margin-right: 7px;
                        }
                    }

                    .btn-expand {
                        .btn--icon {
                            transform: rotate(-90deg);
                        }

                        &.expanded {
                            .btn--icon {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }

        .css-b62m3t-container {
            max-width: 100%;
            width: 100%;
        }

        &-edit {
            &.modal-inner {
                max-width: 1400px;

                .modal-actions {
                    justify-content: flex-end;
                }
            }

            &--comments {
                margin-left: 20px;

                &-list {
                    padding: 10px;
                    border-radius: 10px;
                    background-color: #f5f6f9;
                }
            }
        }
    }
}

.activity-indicator {
    text-align: center;

    &--message {
        margin-top: 10px;
        color: var(--primarydark);
        font-size: .8rem;
    }
}

.box-notification {
    background-color: #f5f6f9;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;

    svg {
        flex: 0 0 20px;
        height: 20px;
        margin-right: 10px;
        fill: var(--fontcolor)
    }

    span {
        font-size: .86rem;
        line-height: 1.16rem;
    }

    &--error {
        background-color: #ffd9e5;
    }
}

.filters-wrapper {
    position: sticky;
    background-color: #fff;
    top: 56px;
    z-index: 4;
    padding-top: 5px;

    .filters {

        &-user {
            display: flex;

            .filters-search--query {
                margin-right: 20px;

                input {
                    min-height: 38px;
                    min-width: 220px;
                }
            }
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .btn-filters {
        margin-bottom: 20px;
    }

    &.filters--open {
        @media (max-width: 768px) {
            display: block;
            position: fixed;
            background-color: #fff;
            width: 100%;
            height: 100%;
            z-index: 6;
            top: 50px;
            left: 0;
            padding: 10px;

            .filters {
                display: block;

                .input-wrapper {
                    width: 100%;

                    .css-b62m3t-container {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.upload-file {
    &--filename {
        margin-bottom: 10px;
    }

    &--file {
        margin-bottom: 10px;
    }

    &--type {
        margin-bottom: 20px;

        .file-input {
            label {
                span {
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .btn-small {
        background-color: var(--secondary);
    }
}

.react-datepicker {
    border-color: var(--bordercolor);
    box-shadow: 0 3px 16px rgba(black, .2);
    z-index: 999;

    &__header {
        background-color: var(--basecoloralt);
        border-bottom-color: var(--bordercolor);
    }

    &__close-icon {
        &::after {
            background-color: var(--bordercoloralt);
        }
    }

    &__navigation-icon {
        &::before {
            border-color: var(--primarylight);
        }
    }

    &__day {
        color: var(--fontcolor);

        &-name {
            color: var(--fontcolor);
        }

        &:hover {
            background-color: var(--boxcolor);
        }

        &--selected,
        &--keyboard-selected {
            background-color: var(--primarylight);

            &:hover {
                background-color: var(--primary);
            }
        }

        &--in-range {
            background-color: var(--primarylight);

        }

        &--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
            background-color: var(--primary);
        }
    }

    &__month {
        &-container {
            background-color: var(--basecolor);
        }
    }

    &__current {
        &-month {
            color: var(--fontcolor);
        }
    }

    &-popper[data-placement^=bottom] {

        .react-datepicker {
            &__triangle {
                margin-left: -14px;

                &::after {
                    border-bottom-color: var(--basecoloralt);
                }

                &::before {
                    border-bottom-color: var(--bordercolor);
                }
            }
        }
    }

    &-popper[data-placement^=top] {

        .react-datepicker {
            &__triangle {
                margin-left: -14px;

                &::before {
                    border-top-color: var(--bordercolor);
                }
            }
        }
    }
}

.tasks {
    border-radius: 10px;
    background-color: var(--boxcolor);
    padding: 14px;
    margin-bottom: 20px;
    max-width: 700px;

    h5 {
        margin-top: 0;
    }

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        border-bottom: 1px solid var(--bordercolor);
        padding-bottom: 10px;

        &:last-child {
            border-bottom: none;
        }

        &>span {
            margin-right: 20px;
        }
    }
}

#confirm-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {

        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#BDBEC2, .3);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 440px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: scroll;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;

            &::after,
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: var(--primary);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }

            &::before {
                transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }

            &:hover {
                opacity: .6;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px 0;
        }
    }
}

.priorities {
    height: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 100px);

    h2 {
        font-size: 14px;
        margin: 0 0 30px;
        padding: 0;
        color: var(--primaryDark);
    }

    .priority {
        font-size: 12px;
        border-bottom: 1px solid var(--boxcolor);
        display: flex;
        justify-content: space-between;
        padding: 10px;

        h3 {
            margin: 0;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.user-priorities {
    overflow-y: scroll;
    max-height: 240px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px;
    background-color: var(--basecoloralt);
    border-radius: 10px;
    margin-bottom: 10px;

    .user-priority {
        font-size: 12px;
        background-color: #fff;
        padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        border: 2px solid var(--boxcolor);

        h3 {
            margin: 0;
        }

        &-meta {
            display: flex;
            justify-content: space-between;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.alert {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    top: calc(50% + 1px);
    transform: translate3d(0, -50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: var(--error);
        width: 100%;
        height: 100%;
    }

    &-input {
        position: relative;
    }
}

.load-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #f5f6f9;
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;
}

.activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }

    100% {
        left: 100%;
    }
}

.card {
    width: 100%;
    position: relative;
    float: left;
    border-radius: 4px;

    &-image {
        height: 200px;
        width: 100%;
        position: relative;
    }

    &-content {
        width: 100%;
        margin-top: 20px;
        min-height: 40px;
        display: flex;
        height: 60px;
    }

    &-text {
        width: 100%;
        height: 30px;
    }
}