.deals {

    .app-content--header {
        position: sticky;
        background-color: #fff;
        top: 54px;
        z-index: 5;
        padding-top: 5px;

        .btn {
            font-size: 12px;
        }

        .btn-view {
            margin-left: 14px;

            svg {
                width: 16px;
                height: 14px;
            }
        }

        .btn-assign {
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    .filters {

        &-wrapper {
            top: calc(54px + 64px);

            &--deal {
                .input-wrapper--date {
                    margin-right: 20px;
                    min-width: 200px;
                }
            }
        }

        &-save {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            margin-left: 20px;

            .input-wrapper--select {
                margin-bottom: 0;
            }

            .btn-save-filters {
                padding: 9.5px 16px;
                margin-left: 6px;
                background-color: var(--secondary);
            }
        }

        &-deal {

            .filters-search {
                &--query {
                    margin-right: 20px;
                    width: 100%;
                }
                &--checkboxes {
                    display: flex;

                    .input-wrapper {
                        margin-right: 20px;
                        min-width: 120px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            &--area {
                display: flex;

                .input-wrapper {
                    margin-right: 6px;
                    position: relative;

                    &:last-child {
                        margin-right: 0;

                        .css-b62m3t-container {
                            min-width: 130px;
                        }
                    }
                }

                .input-wrapper--input {
                    min-width: 130px;
                }
            }

            &--row {
                display: flex;

                .input-wrapper {
                    width: 100%;

                    &--select {
                        margin-right: 20px;
                        max-width: 100%;
                        
                        .css-b62m3t-container {
                            max-width: 100%;
                        }
                    }
                }

                .input-wrapper--checkbox {
                    margin-top: 4px;
                }
            }
            
            &--status {
                .css-b62m3t-container {
                    max-width: 100%;
                }
            }

            .css-wsp0cs-MultiValueGeneric {
                font-size: 70%;
            }
        }
    }

    .filter-table {
        table {
            th {
                top: calc(54px + 64px + 113px)
            }
        }
        .property-status {
            display: flex;
            align-items: center;

            & > div {
                flex: 0 0 14px;
                height: 14px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
        
        .deal-property {
            padding-bottom: 5px;
            position: relative;
            padding-left: 16px;
            font-size: 12px;
            
            &-dot {
                display: block;
                width: 8px;
                height: 8px;
                background-color: transparent;
                border-radius: 20px;
                position: absolute;
                left: 0;
                top: 6px;
            }

            & > div {
                padding: 2px 0;

                &:nth-child(2) {
                    font-weight: bold;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .deal-comment {
            font-size: 12px;
        }
    }
}

#modal .modal-deal {
    .size-row {
        display: flex;

        .input-wrapper {
            &:first-child {
                flex: 1;
                margin-right: 5px;
            }

            &--select {
                .css-b62m3t-container {
                    min-width: 0;
                }
            }
        }
    }

    .status-select {
        grid-column: 1/3;
    }
}

#modal .modal-deal-edit {

    .properties {
        .property {
            &-collapsible {
                cursor: pointer;

                &--header {
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #f5f6f9;

                    & > span {
                        display: flex;
                        align-items: center;

                        .property-status {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }

                        span {
                            font-weight: bold;
                            color: var(--primary);
                        }
                    }

                    svg {
                        transform: rotate(-90deg);
                        width: 14px;
                        height: 14px;
                        fill: var(--primary);
                    }
                }
            }

            .size-row {
                display: flex;

                .input-wrapper {
                    &:first-child {
                        flex: 1;
                        margin-right: 5px;
                    }

                    &--select {
                        .css-b62m3t-container {
                            min-width: 0;
                        }
                    }
                }
            }

            .status-select {
                grid-column: 1/3;
            }
        }
    }

    .comments {
        margin-left: 20px;

        .comment {
            &-view {
                margin-bottom: 10px;
                background-color: var(--primarydark);
                color: #fff;
                padding: 10px;
                border-radius: 6px;
                margin-bottom: 10px;
                border-bottom-right-radius: 0;

                &--content {
                    margin-bottom: 16px;

                    .input-wrapper--control {
                        .input-wrapper--input {
                            box-shadow: 0 0 0 2px rgba(#fff, .1);
                            border-radius: 4px;
                        }

                        &-focused {
                            .input-wrapper--input {
                                box-shadow: 0 0 0 2px var(--primarylight);
                            }
                        }
                    }

                    textarea {
                        background-color: transparent;
                        border: 0;
                        color: #fff;
                    }
                }

                &--meta {
                    display: flex;
                    justify-content: space-between;
                    font-size: .8rem;

                    &--author {
                        font-weight: bold;
                    }
                }

                &--actions {
                    margin-top: 10px;
                    padding-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 1px solid rgba(#fff, .1);

                    .btn-edit {
                        margin-left: 20px;
                        background-color: transparent;
                        font-weight: bold;
                        padding: 0;
                    }

                    .btn-remove {
                        background-color: transparent;
                        padding: 0;
                
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

#modal .modal-properties-import {
    &.modal-inner {
        max-width: 400px;
    }
}

#modal .modal-filter-view {
    &.modal-inner {
        max-width: 400px;
    }
}