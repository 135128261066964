.bot {
    .filters {
        display: flex;
        align-items: flex-end;
        padding-bottom: 16px;

        .input-wrapper {
            margin-bottom: 0;
            margin-right: 20px;
        }

        .btn {
            min-height: 38px;
        }
    }

    .filter-table {

        .bot-property {
            padding-bottom: 5px;
            margin-bottom: 5px;
            position: relative;
            padding-left: 16px;
            
            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                background-color: var(--primary);
                border-radius: 20px;
                position: absolute;
                left: 0;
                top: 6px;
            }

            & > div {
                padding: 2px 0;

                &:nth-child(2) {
                    font-weight: bold;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}