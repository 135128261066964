@import 'colors';
@import 'elements';
@import 'header';
@import 'components';

* {
    box-sizing: border-box;
}
  
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-content {
    min-height: calc(100vh - 50px);
    padding: 64px 10px 0;
    max-width: var(--maxWidth);
    margin: 0 auto;

    &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-actions {
            margin-left: 40px;
            display: flex;
            align-items: center;

            .assign-deal {
                display: flex;
                align-items: center;
                margin: 0 14px;
                background-color: var(--basecoloralt);
                padding: 6px;
                border-radius: 6px;
                
                .input-wrapper--select {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }

            .btn {
                margin-right: 14px;
                padding: 10px 16px;
                background-color: var(--secondary);
    
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @media (max-width: 768px) {
        overflow-x: scroll;
    }
}