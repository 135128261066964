:root {
    --maxWidth: 1640px;
    
    .theme--light {
        --primary: #4dd45f;
        --primarylight: #bce076;
        --primarydark: #49a175;

        --secondary: #38424b;

        --basecolor: #ffffff;
        --basecoloralt: #f9f9fb;

        --boxcolor: #f4f4f6;

        --fontcolor: #242425;
        --fontcoloralt: #4b4b4d;

        --bordercolor: #e0e4e9;
        --bordercoloralt: #c8d0db;

        --buttoncolor: #fff;

        --red: #ff0045;

        --error: rgb(245, 20, 73);
        --success: rgb(1, 201, 108);
    }
}