.login {
    width: 100vw;
    height: 100vh;
    background-color: #f5f6f9;
    display: flex;
    align-items: center;
    justify-content: center;

    &-background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;

        &--image {
            width: 100%;
            height: 100%;
            background-image: url('../../../public/field.jpg');
            background-size: cover;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(15px);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
    }

    &-brand {
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.06rem;
        font-size: 0.76rem;
        margin-bottom: 30px;
        color: var(--primarydark);
    }

    &-method {
        position: relative;
        z-index: 1;
        flex: 1;
        max-width: 400px;
        background-color: #fff;
        padding: 40px;
        border-radius: 10px;
        margin: 10px;

        h4 {
            margin-top: 0;
        }

        .login-actions {
            display: flex;
            justify-content: space-between;

            .btn {
                margin-right: 20px;
                flex: 1;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .other-actions {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;

            a {
                cursor: pointer;
                color: var(--secondary);
                font-size: .8rem;
            }
        }
    }
}