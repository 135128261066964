.app-header {
    background-color: var(--basecolor);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(black, .08);
    z-index: 9;

    &--inner {
        width: 100%;
        padding: 6px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: var(--maxWidth);
    }

    &--info {
        border-left: 2px solid var(--boxcolor);
        padding-left: 10px;
        margin-left: 10px;
        cursor: pointer;

        span {
            display: block;
            text-align: right;
            font-size: 11px;
        }

        &-name {
            color: var(--fontcoloralt);
            text-transform: capitalize;
        }

        &-title {
            color: var(--bordercoloralt);
        }

        @media (max-width: 991px) {
            padding-right: 10px;
            margin-top: 30px;
        }
    }

    &--actions {
        margin-left: 20px;

        @media (max-width: 991px) {
            text-align: right;
            padding-right: 10px;
            margin-top: 30px;
        }
    }

    .brand {
        position: relative;
        z-index: 13;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        span {
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: .06rem;
            font-size: .76rem;
            color: var(--secondary);
        }
    }

    nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            text-decoration: none;
            color: var(--primary);
            font-weight: 600;
            white-space: nowrap;
        }

        .nav-sub {
            position: relative;
            padding: 7px 0;
            
            a {
                cursor: pointer;
            }

            &--label {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: var(--primary);
                font-weight: 600;
                white-space: nowrap;
                padding: 6px 10px;
                font-size: 14px;
                cursor: pointer;

                &-disabled {
                    cursor: default;
                    color: var(--bordercoloralt);
                }
            }

            &--badge {
                position: relative;
                width: 14px;
                height: 14px;
                border-radius: 100%;
                background-color: var(--red);
                color: #fff;
                text-align: center;
                font-size: 10px;
                margin-left: 3px;
            }

            &--dropdown {
                display: none;
                position: absolute;
                background-color: var(--basecolor);
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                padding: 10px 0 10px;
                box-shadow: 0 8px 10px rgba(black, .08);
                top: 100%;
                min-width: 140px;

                a {

                    &.active {
                        background-color: var(--boxcolor);
                        color: var(--fontcoloralt);

                        &::after {
                            display: none;
                        }

                        &:hover {
                            color: var(--bordercoloralt);
                        }
                    }
                }

                &-priorities {
                    width: 100%;
                    width: 430px;
                    right: 0;
                    padding: 0;
                }
            }

            &:hover {
                .nav-sub--dropdown {
                    display: block;
                }
            }
        }

        a {
            display: block;
            padding: 6px 10px;
            position: relative;
            font-size: 14px;

            &:active {
                background-color: transparent;
                -webkit-tap-highlight-color: transparent;
            }

            &:hover {
                color: var(--primarylight);
            }

            &.active {

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    transform: translate3d(-50%, 0, 0);
                    bottom: 3px;
                    width: calc(100% - 20px);
                    height: 2px;
                    background-color: var(--primary);
                }

                &:hover {
                    color: var(--primary);
                }
            }
        }

        @media (max-width: 991px) {
            display: block;
            position: fixed;
            background-color: rgba(white, .9);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 12;
            padding-top: 70px;
            padding-right: 10px;
            opacity: 0;
            pointer-events: none;
            transition: opacity .4s ease;
            backdrop-filter: blur(2px);

            a {
                text-align: right;

                &.active {
                    font-weight: bold;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .menu-btn {
        display: none;
        width: 20px;
        height: 14px;
        -webkit-appearance: none;
        border: none;
        box-shadow: none;
        background-color: transparent;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        z-index: 13;
        cursor: pointer;
        
        div,
        &::after,
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: var(--primarylight);
            border-radius: 4px;
            position: absolute;
            transition: all .2s ease;
        }
        
        div {
            top: 50%;
            width: calc(100% - 6px);
            transform: translate3d(0, -50%, 0);
        }
        
        &::before {
            top: 0;
        }
        
        &::after {
            bottom: 0;
            width: calc(100% - 3px);
        }
        
        @media (max-width: 991px) {
            display: block;
        }
    }

    &.nav-open {

        @media (max-width: 991px) {
            nav {
                pointer-events: all;
                opacity: 1;
            }
        
            .menu-btn {
                
                div {
                opacity: 0;
                transform: translate3d(100%, -50%, 0);
                }
            
                &::before {
                transform: translate3d(0, 6px, 0) rotate(45deg);
                }
            
                &::after {
                width: 100%;
                transform: translate3d(0, -6px, 0) rotate(-45deg);
                }
            }
        }
    }
}