.settings {

    &-statuses {
        & > h2 {
            margin-bottom: 0;
            margin-top: 60px;
        }
    }

    .property-statuses {
        display: flex;

        &--add {

            h4 {
                margin-top: 0;
            }
        }

        &--list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            margin-right: 60px;
            margin-bottom: 20px;
        }

        &--status {
            position: relative;
            padding: 10px;
            background-color: #f5f6f9;
            border-radius: 6px;
            margin-bottom: 10px;

            .btn-remove {
                position: absolute;
                top: -6px;
                right: -6px;
                border-radius: 20px;
                width: 30px;
                height: 30px;
                background-color: var(--secondary);

                .btn--icon {
                    margin-right: 0;
                }

                &.btn--has-icon {
                    padding: 0;
                }
            }
        }
    }
}